<template>
  <div id="app"
       style="min-height: 100vh; background-color: #F8F8F8; padding-top: 20px; padding-left: 3vw; padding-right: 3vw;">
    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);">
      <div style="display: flex; border-bottom: 1px solid #F1F1F1; padding: 16px 12px 16px 12px;"
           @click="showInterval = true">
        <span style="flex: 1; line-height: 14px; font-size: 14px;">违规行为报警推送间隔</span>
        <span style="line-height: 14px; font-size: 14px;">{{ settingData.interval }}分钟</span>
        <img
            style="width: 7px; height: 12px; margin-left: 8px; align-self: center;"
            :src="require('../../../src/assets/images/kitchen/icon_arrow_right.png')"
            alt=""/>
      </div>

      <div style="display: flex; padding: 16px 12px 16px 12px;" @click="showTimes = true">
        <span style="flex: 1; line-height: 14px; font-size: 14px;">违规行为推送频次 / 天</span>
        <span style="line-height: 14px; font-size: 14px;">{{ settingData.times }}</span>
        <img
            style="width: 7px; height: 12px; margin-left: 8px; align-self: center;"
            :src="require('../../../src/assets/images/kitchen/icon_arrow_right.png')"
            alt=""/>
      </div>

    </div>

    <div style="line-height: 16px; font-size: 16px; margin-top: 18px; margin-bottom: 12px;">布防时间</div>

    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; padding: 16px 12px 16px 12px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);">
      <div v-for="(item, index) in settingData.defenses" :key="index">
        <div style="display: flex; margin-bottom: 16px;">
          <img
              style="width: 13px; height: 14px; align-self: center;"
              :src="require('../../../src/assets/images/kitchen/icon_calander.png')"
              alt=""/>
          <span style="line-height: 14px; font-size: 14px; margin-left: 2px;">{{ item.name }}</span>
        </div>

        <div v-for="(item1, index1) in item.list" :key="index1" style="margin-bottom: 16px;">
          <div style="display: flex">
            <div v-for="(item2, index2) in 3" :key="index2" style="flex: 1">
              <div v-if="index2 % 3 === 0" style="">
                {{ handleTimes(item1[0]) }}
              </div>
              <div v-if="index2 % 3 === 1" style="text-align: center">
                {{ handleTimes(item1[1]) }}
              </div>
              <div v-if="index2 % 3 === 2" style="text-align: right">
                {{ handleTimes(item1[2]) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="settingData.defenses.length === 0" style="padding: 16px 12px 16px 12px;">暂无布防信息</div>

      <div style="border-bottom: 1px solid #F1F1F1;"/>

      <div style="display: flex; padding-top: 16px;" @click="onClickDefense">
        <span style="flex: 1; line-height: 14px; font-size: 14px;">布防</span>
        <img
            style="width: 7px; height: 12px; margin-left: 8px; align-self: center;"
            :src="require('../../../src/assets/images/kitchen/icon_arrow_right.png')"
            alt=""/>
      </div>
    </div>

    <div class="loading_all" v-if="showInterval">
      <div
          style="width: 100%; background-color: #FFFFFF; margin-left: 12px; margin-right: 12px; border-radius: 4px;">
        <div style="display: flex; padding: 16px 14px 0px 14px">
          <span style="flex: 1; color: #333333; font-size: 16px;">请填写违规行为报警推送间隔</span>
        </div>

        <div style="margin-top: 16px; padding: 0px 14px 0px 14px">
          <van-field
              v-model="interval"
              type="digit"
              placeholder="请输入间隔分钟"
              style="height: 45px; border: 1px solid #f1f1f1; border-radius: 4px;"
          />
        </div>

        <div style="display: flex; border-top: 1px solid #F1F1F1; margin-top: 16px;">
          <div style="flex: 1; height: 40px; line-height: 40px; text-align: center;" @click="onClickIntervalCancel">
            取消
          </div>
          <div
              style="flex: 1; height: 40px; line-height: 40px; text-align: center; background-color: #34AC40; color: #FFFFFF; border-radius: 0px 0px 4px 0px"
              @click="onClickIntervalConfirm">
            确认
          </div>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="showTimes">
      <div
          style="width: 100%; background-color: #FFFFFF; margin-left: 12px; margin-right: 12px; border-radius: 4px;">
        <div style="display: flex; padding: 16px 14px 0px 14px">
          <span style="flex: 1; color: #333333; font-size: 16px;">填写违规行为推送频次</span>
        </div>

        <div style="margin-top: 16px; padding: 0px 14px 0px 14px">
          <van-field
              v-model="times"
              type="digit"
              placeholder="请输入次数/天"
              style="height: 45px; border: 1px solid #f1f1f1; border-radius: 4px;"
          />
        </div>

        <div style="display: flex; border-top: 1px solid #F1F1F1; margin-top: 16px;">
          <div style="flex: 1; height: 40px; line-height: 40px; text-align: center;" @click="onClickTimesCancel">
            取消
          </div>
          <div
              style="flex: 1; height: 40px; line-height: 40px; text-align: center; background-color: #34AC40; color: #FFFFFF; border-radius: 0px 0px 4px 0px"
              @click="onClickTimesConfirm">
            确认
          </div>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

  </div>
</template>

<script>
import {post} from "../../utils/httpKitchen";
import {Notify} from "vant";

export default {
  name: "AlarmSetting",
  data() {
    return {
      loading: false,
      showInterval: false,
      showTimes: false,
      interval: '',
      times: '',
      settingData: {
        interval: 0,
        times: 0,
        defenseSwitchList: [],
        defenses: []
      }
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    this.fetchSetting();
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$router.push('/kitchen/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    fetchSetting() {
      post('api/kitchen/app/v1/setting/search', {})
          .then(res => {
            if (!res) {
              Notify({type: 'danger', message: '配置异常'});
              this.$router.back(-1);
              return;
            }

            this.settingData.defenses = [];
            for (const setting of res.data) {
              if (setting.setKey === 'KITCHEN_ALARM_INTERVAL') {
                this.settingData.interval = setting.setValue;
              } else if (setting.setKey === 'KITCHEN_ALARM_EVERYDAY_TIME') {
                this.settingData.times = setting.setValue;
              } else if (setting.setKey === 'KITCHEN_DEFENCE_MON') {
                this.packageDefenseTimeData(setting, '周一');
              } else if (setting.setKey === 'KITCHEN_DEFENCE_TUES') {
                this.packageDefenseTimeData(setting, '周二');
              } else if (setting.setKey === 'KITCHEN_DEFENCE_WED') {
                this.packageDefenseTimeData(setting, '周三');
              } else if (setting.setKey === 'KITCHEN_DEFENCE_THUR') {
                this.packageDefenseTimeData(setting, '周四');
              } else if (setting.setKey === 'KITCHEN_DEFENCE_FRI') {
                this.packageDefenseTimeData(setting, '周五');
              } else if (setting.setKey === 'KITCHEN_DEFENCE_SAT') {
                this.packageDefenseTimeData(setting, '周六');
              } else if (setting.setKey === 'KITCHEN_DEFENCE_SUN') {
                this.packageDefenseTimeData(setting, '周日');
              }
            }

          })
          .catch(err => {
            Notify({type: 'danger', message: '配置异常'});
            this.$router.back(-1);
            return;
          });
    },
    packageDefenseTimeData(setting, name) {
      if (!setting.defenceSettings || setting.defenceSettings.length <= 0) {
        return;
      }
      let data = {
        name: name,
        list: []
      };
      let rowData = [];
      for (const defenceSetting of setting.defenceSettings) {
        rowData.push({startTime: defenceSetting.startTime, endTime: defenceSetting.endTime});
        if (rowData.length === 3) {
          data.list.push(rowData);
          rowData = [];
        }
      }
      data.list.push(rowData);
      this.settingData.defenses.push(data);
    },
    handleTimes(item) {
      if (!item) {
        return "";
      }

      let startHour = Math.floor(item.startTime / 60).toString();
      if (startHour.length === 1) {
        startHour = '0' + startHour;
      }
      let startMinute = (item.startTime % 60).toString();
      if (startMinute.length === 1) {
        startMinute = '0' + startMinute;
      }
      let endHour = Math.floor(item.endTime / 60).toString();
      if (endHour.length === 1) {
        endHour = '0' + endHour;
      }
      let endMinute = (item.endTime % 60).toString();
      if (endMinute.length === 1) {
        endMinute = '0' + endMinute;
      }
      return startHour + ":" + startMinute + "-" + endHour + ":" + endMinute;
    },
    onClickIntervalConfirm() {
      if (0 >= this.interval || 120 < this.interval) {
        Notify({type: 'warning', message: '请填写范围为1~120'});
        return;
      }

      this.loading = true;
      this.showInterval = false;
      post('api/kitchen/app/v1/setting/update/cache/refresh', {kitchenAlarmInterval: this.interval})
          .then(res => {
            this.settingData.interval = this.interval;
            this.interval = '';
          })
          .catch(err => {
            Notify({type: 'danger', message: '保存失败,请重试'})
          })
          .finally(() => {
            this.loading = false;
          });
    },
    onClickIntervalCancel() {
      this.showInterval = false;
      this.interval = '';
    },
    onClickTimesConfirm() {
      if (20 > this.times || 999 < this.times) {
        Notify({type: 'warning', message: '请填写范围为20~999'});
        return;
      }

      this.loading = true;
      this.showTimes = false;
      post('api/kitchen/app/v1/setting/update/cache/refresh', {kitchenAlarmEverydayTime: this.times})
          .then(res => {
            this.settingData.times = this.times;
            this.times = '';
          })
          .catch(err => {
            Notify({type: 'danger', message: '保存失败,请重试'})
          })
          .finally(() => {
            this.loading = false;
          });
    },
    onClickTimesCancel() {
      this.showTimes = false;
      this.times = '';
    },
    onClickDefense() {
      this.$router.push({name: 'kitchenAlarmDefenseSetting'});
    }
  }
}
</script>

<style scoped>

</style>